:root {
    --bs-primary: #61ad8e;
    --bs-secondary: #ad6d76;
    --bs-info: #b7cddf;
    /*--bs-light: ;*/
    --bs-dark: #383e3e;
    --bs-body-font-weight: 300;
    --bs-font-sans-serif: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#wrapper {
    width: 100%;
    overflow-x: hidden;
    display: flex;
}

#pageWrapper {
    min-height: 100vh;
    padding: 0 15px;
    position: relative !important;
    flex-shrink: 1;
    -webkit-flex-shrink: 1;
    width: 100%;
}

[name=pageBar] {
    background-color: var(--bs-white);
}

[name=navBar] {
    background-color: var(--bs-white);
    border-bottom: 1px solid var(--bs-gray-200);
}

[name=navBar] [name=brandLink]:hover,
[name=navBar] [name=brandLink]:active {
    background-color: transparent;
    color: var(--bs-gray-700)
}

body {
    background-color: var(--bs-gray-100);
}

.offcanvas {
    background-color: var(--bs-gray-100);
}

.offcanvas-header {
    border-bottom: 1px solid var(--bs-gray-200);
}

.dropdown-item {
    font-weight: 300;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--bs-primary);
}

.form-control.is-valid,
.was-validated
.form-control:valid {
    border-color: var(--bs-primary)
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 200;
}

hr {
    background: linear-gradient(90deg, rgba(0,0,0,.3) 0%, rgba(0,0,0,.3) 35%, rgba(0,0,0,0) 100%); }